<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>

    <ion-title>Please specify reason</ion-title>

    <ion-buttons slot="end">
      <ion-button color="primary" [disabled]="!form || !form.valid" (click)="save()">
        <span *ngIf="!saving">Save</span>
        <ion-spinner *ngIf="saving"></ion-spinner>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
    <form [formGroup]="form" *ngIf="form">

      <ion-item lines="none" *ngIf="['Suggested', 'Accepted', 'Rejected'].indexOf(form.controls['type'].value) == -1">
        <div tabindex="0"></div>
        <ion-select formControlName="type">
          <ion-select-option>Internal Note</ion-select-option>
          <ion-select-option>Phone Call</ion-select-option>
          <ion-select-option>Email</ion-select-option>
          <ion-select-option>Meeting</ion-select-option>
          <ion-select-option>Interview</ion-select-option>
          <ion-select-option>Task</ion-select-option>
        </ion-select>
      </ion-item>

      <ckeditor [config]="editorConfig" #ckeditor [editor]="Editor" (ready)="onEditorReady()" (change)="onChange($event)"></ckeditor>
    </form>
  </div>
</ion-content>
