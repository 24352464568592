import { Store } from './store';
import { CompanyContact } from './company-contact';
import { Request } from './request';
import {Transfer} from "./transfer";
import {Mall} from "./mall";
import {Contact} from "./contact";
import { Country } from './country';
import { Contract } from './contract';

export class Company {
    company_id: number;
    parent_company_id: number;
    company_name: string;
    company_common_name_en: string;
    company_common_name_ar: string;
    company_description_en: string;
    company_description_ar: string;
    company_website: string;
    company_email: string;
    company_logo: string;
    commercial_licence: string;
    company_status: number;
    company_status_override: number;
    total_candidates: number;
    company_hourly_rate: number;
    company_bonus_commission: number;
    company_followup: any;
    company_last_followup_datetime: any;
    company_next_followup_datetime: any;
    company_followup_interval_weeks: any;
    currency_pref: number;
    total_candidate: number;
    total_suggestions: number;
    company_approved_to_hire: any;
    password: any;
    country_id: number;
    currency_code: string;
    subcompanies: Company[];
    subCompanies: Company[];
    stores: Store[];
    files: any[];
    brands: any[];
    notes: any[];
    transferInLast40Days: number;
    malls: Mall[];
    companyContacts: CompanyContact[];
    contacts: Contact[];
    requests: Request[];
    parentTransfers: Transfer[];
    stats: any[];
    country: Country;
    companyStats: any;
    contracts: Contract[];
}
