<ion-header mode="md" [class.add-border]="borderLimit" class="ion-no-border">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="container">
    <ion-title>Update account</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
    <form [formGroup]="form" *ngIf="form">
      <ion-list lines="none">
          
        <div class="logo-wrapper ion-text-center" >

          <div *ngIf="!progress">
            
            <img tappable (click)="triggerUpdatePhoto($event)"
                *ngIf="form.controls['logo'].value && form.controls['logo'].value.length > 0"
                src="{{ form.controls['logo_path'].value }}"><br />

            <div tappable (click)="triggerUpdatePhoto($event)" class="no-logo"
                *ngIf="!form.controls['logo'].value || form.controls['logo'].value.length == 0">
              <ion-icon src="assets/images/placeholder.png"></ion-icon>
            </div>

            <ion-button color="dark" id="upload-pic" class="change-logo ion-text-center" #btnChangePhoto
                        (click)="updatePhoto($event);">
              <ion-icon src="assets/images/md-cloud-upload.svg"></ion-icon>&nbsp;
              <span *ngIf="model.staff_photo">Replace profile image</span>
              <span *ngIf="!model.staff_photo">Select profile image</span>
            </ion-button>
          </div>

          <input class="upload" type='file' #fileInput (change)="browserUpload($event)" accept=".jpg,.jpeg,.png">
        </div>

        <div class="progress-wrapper" *ngIf="progress">
          <div class="progress-box">

            <h4>Uploading… <span>{{ progress + '%' }}</span></h4>

            <div class="progress-bar">
              <div class="progress" [style.width]="progress + '%'"></div>
            </div>

            <ion-icon *ngIf="currentTarget" tappable src="assets/images/close.svg" (click)="cancelUpload()">
            </ion-icon>
          </div>
        </div>

        <ion-item lines="none">
          <div tabindex="0"></div>
          <ion-input type="text" formControlName="name" placeholder="Your name"></ion-input>
        </ion-item>
        
        <ion-item lines="none">
          <div tabindex="0"></div>
          <ion-input type="text" formControlName="staff_job_title" placeholder="Your job title"></ion-input>
        </ion-item>

        <ion-item>
          <div tabindex="0"></div>
          <ion-label>Receive notification?</ion-label>
          <ion-toggle (ionChange)="onchange($event)" formControlName="staff_notification"></ion-toggle>
        </ion-item>
        
        <ion-item>  
          <div tabindex="0"></div>
          <ion-label>Two-Step Auth</ion-label>
          <ion-toggle (ionChange)="onTwoStepAuthChange($event)" formControlName="enable_two_step_auth"></ion-toggle>
        </ion-item>
      </ion-list>
    </form>
  </div>
</ion-content>
<ion-footer class="ion-text-center ion-no-border ion-transparent">
  <div class="container">
    <ion-button [disabled]="!form || !form.valid || !form.dirty || this.saving" (click)="save()" class="btn-submit">
      <span *ngIf="!saving">{{"Submit"}}</span>
      <ion-spinner *ngIf="saving"></ion-spinner>
    </ion-button>
  </div>
</ion-footer>
