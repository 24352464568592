<ion-header mode="md">
  <ion-toolbar class="container">
    <ion-buttons slot="start">
      <ion-back-button icon="assets/images/icon-back.svg" (click)="close()"></ion-back-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-toolbar class="container">
    <ion-title>{{ operation }}</ion-title>

    <ion-buttons slot="end">
      <ion-button clear class="cancel" color="primary" (click)="close()">
        Cancel
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content scrollEvents="true" (ionScroll)="logScrolling($event)">
  <div class="container">
    <form [formGroup]="form" *ngIf="form">
      <ion-list lines="none" mode="md">

        <div class="image-upload">
            <ion-grid slot="start">
              <ion-row>
                <ion-col size="4">
                  <img tappable (click)="triggerUpdatePhoto($event)"
                      *ngIf="form.controls['logo'].value && form.controls['logo'].value.length > 0"
                      src="{{ form.controls['logo_path'].value }}">

                  <div tappable (click)="triggerUpdatePhoto($event)" class="no-logo"
                      *ngIf="!form.controls['logo'].value || form.controls['logo'].value.length == 0">
                    <ion-icon src="assets/images/brand-no-image.svg"></ion-icon>
                  </div>
                </ion-col>
                <ion-col size="8">
                  <a clear id="upload-pic" #btnChangePhoto (click)="updatePhoto($event);">
                    <span *ngIf="model.brand_logo">Replace Brand Logo</span>
                    <span *ngIf="!model.brand_logo">Upload Brand Logo</span>
                  </a>
                </ion-col>
              </ion-row>
            </ion-grid>
        </div>
        
        <input class="upload" type='file' #fileInput (change)="browserUpload($event)" accept=".jpg,.jpeg,.png">

        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label position="floating">Name - English</ion-label>
          <ion-input type="text" formControlName="name_en"></ion-input>
        </ion-item>

        <ion-item class="input-wrapper" >
          <div tabindex="0"></div>
          <ion-label position="floating">Name - Arabic</ion-label>
          <ion-input type="text" formControlName="name_ar"></ion-input>
        </ion-item>

      </ion-list>
    </form>

    <div class="progress-wrapper" *ngIf="progress">
      <div class="progress-box">

        <h4>Uploading… <span>{{ progress + '%' }}</span></h4>

        <div class="progress-bar">
          <div class="progress" [style.width]="progress + '%'"></div>
        </div>

        <ion-icon *ngIf="currentTarget" tappable src="assets/images/close.svg" (click)="cancelUpload()">
        </ion-icon>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer class="ion-no-border ion-text-center">
  <div class="container">
    <ion-button class="btn-save" [disabled]="!form || form.invalid || saving" (click)="save()">
      <span *ngIf="!saving">Add New Brand</span>
      <ion-spinner *ngIf="saving"></ion-spinner>
    </ion-button>
  </div>
</ion-footer>
