<!------------------------ candidate -------------------->

<div *ngIf="loading && type == 'candidate'" #container>
  <ion-card class="candidate">

    <ion-card-content>

      <div class="background-masker image"></div>

      <h2 class="background-masker name"></h2>

      <small class="city">
        <div class="background-masker city-location"></div>
      </small>

      <div class="clearfix"></div>

      <p class="background-masker objective"></p>

      <hr />

      <p class="background-masker work"></p>

    </ion-card-content>
  </ion-card>

  <ion-card class="candidate">
    <ion-card-content>

      <div class="background-masker image"></div>

      <h2 class="background-masker name"></h2>

      <small class="city">
        <div class="background-masker city-location"></div>
      </small>

      <div class="clearfix"></div>

      <p class="background-masker objective"></p>

      <hr />

      <p class="background-masker work"></p>

    </ion-card-content>
  </ion-card>
</div>

<!------------------------ fulltimer -------------------->

<div *ngIf="loading && type == 'fulltimer'">
  <ion-card class="fulltimer">
    <ion-card-content>

      <div class="background-masker image"></div>

      <h2 class="background-masker name"></h2>

      <small class="city">
        <div class="background-masker city-location"></div>
      </small>

      <div class="clearfix"></div>

      <p class="background-masker objective"></p>

      <hr />

      <p class="background-masker work"></p>

    </ion-card-content>
  </ion-card>

  <ion-card class="fulltimer">
    <ion-card-content>

      <div class="background-masker image"></div>

      <h2 class="background-masker name"></h2>

      <small class="city">
        <div class="background-masker city-location"></div>
      </small>

      <div class="clearfix"></div>

      <p class="background-masker objective"></p>

      <hr />

      <p class="background-masker work"></p>

    </ion-card-content>
  </ion-card>
</div>

<!------------------- fulltimer-filter ------------------------>

<div class="fulltimer-filter" *ngIf="loading && type == 'fulltimer-filter'">
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
</div>

<!------------------- candidate-filter ------------------------>

<div class="candidate-filter" *ngIf="loading && type == 'candidate-filter'">
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
  <div class="wrapper">
    <div class="background-masker title"></div>
  </div>
</div>

<!------------------ contract-list -------------------->

<div class="contract-list" *ngIf="loading && type == 'contract-list'">
  <ion-list>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
  </ion-list>
</div>

<!------------------ text-list -------------------->

<div class="text-list" *ngIf="loading && type == 'text-list'">
  <ion-list>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-item>
  </ion-list>
</div>

<!------------------ text-list-view -------------------->

<div class="text-list-view" *ngIf="loading && type == 'text-list-view'">
  <ion-list>
    <ion-item>
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text></ion-skeleton-text>
        </h5>
        <p>
          <ion-skeleton-text></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text></ion-skeleton-text>
        </h5>
        <p>
          <ion-skeleton-text></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text></ion-skeleton-text>
        </h5>
        <p>
          <ion-skeleton-text></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text></ion-skeleton-text>
        </h5>
        <p>
          <ion-skeleton-text></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text></ion-skeleton-text>
        </h5>
        <p>
          <ion-skeleton-text></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
    <ion-item>
      <div tabindex="0"></div>
      <ion-label>
        <h5>
          <ion-skeleton-text></ion-skeleton-text>
        </h5>
        <p>
          <ion-skeleton-text></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-list>
</div>

<!------------------ candidate-list -------------------->

<div class="candidate-list" *ngIf="loading && type == 'candidate-list'">
  <ion-card>

    <ion-item lines="none">
      <div tabindex="0"></div>
      <ion-avatar slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-avatar>
      <ion-label>
        <h3>
          <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
        </h3>
        <p>
          <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
        </p>
      </ion-label>
    </ion-item>
  </ion-card>

</div>

<!------------------ candidate-form -------------------->

<div class="candidate-list" *ngIf="loading && type == 'candidate-form'">
  <!-- Grid for Required File Uploads -->
  <ion-grid id='uploads'>
    <ion-row>
      <ion-col>
        <ion-skeleton-text class="height-80" animated></ion-skeleton-text>
      </ion-col>
      <ion-col>
        <ion-skeleton-text class="height-80" animated></ion-skeleton-text>
      </ion-col>
      <ion-col>
        <ion-skeleton-text class="height-80" animated></ion-skeleton-text>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- Rest of Form -->
  <ion-card>
    <ion-card-header>
      Civil ID
    </ion-card-header>
    <ion-card-content>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">Civil ID Number</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">Civil ID Expiry Date</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      Personal Information
    </ion-card-header>
    <ion-card-content>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">Name [English]</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">Name [Arabic]</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">Birth Date</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">Phone Number</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      Payment
    </ion-card-header>
    <ion-card-content>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">Hourly Rate</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      Bank Information
    </ion-card-header>
    <ion-card-content>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">Beneficiary Name</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">IBAN</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-header>
      Login Information
    </ion-card-header>
    <ion-card-content>
      <ion-item>
        <div tabindex="0"></div>
        <ion-label position="stacked">Email</ion-label>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-item>
    </ion-card-content>
  </ion-card>
</div>

<!------------------ stats -------------------->

<div class="stats" *ngIf="loading && type == 'stats'">
  <ion-card>
    <ion-card-content>
      <div class="count">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
      <div class="message">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <div class="count">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
      <div class="message">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <div class="count">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
      <div class="message">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <div class="count">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
      <div class="message">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>

</div>

<!--------------------------- candidate-view -------------------->

<div class="candidate-view" *ngIf="loading && type == 'candidate-view'">

  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
        <div class="left-side">
          <ion-thumbnail>
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-thumbnail>

          <h2 class="candidate-name">
            <ion-skeleton-text animated></ion-skeleton-text>
          </h2>

          <h4 class="candidate-name">
            <ion-skeleton-text animated></ion-skeleton-text>
          </h4>

          <h5 class="hourly-rate">
            <ion-skeleton-text animated></ion-skeleton-text>
          </h5>

          <div class="birth-date">

            <span class="txt-born-on">
              <ion-skeleton-text animated></ion-skeleton-text>
            </span>

            <ion-skeleton-text class="txt-birth-date" animated></ion-skeleton-text>

          </div>

          <ion-skeleton-text class="job-status" animated></ion-skeleton-text>

          <div class="objective">
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
            <ion-skeleton-text animated></ion-skeleton-text>
          </div>

          <div class="suggestion-wrapper">
            <h5 class="heading">Candidate has been</h5>

            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Suggested</ion-label>
              <ion-note slot="end">
                <ion-skeleton-text animated></ion-skeleton-text>
              </ion-note>
            </ion-item>

            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Accepted</ion-label>
              <ion-note class="accepted" slot="end">
                <ion-skeleton-text animated></ion-skeleton-text>
              </ion-note>
            </ion-item>

            <ion-item>
              <div tabindex="0"></div>
              <ion-label>Rejected</ion-label>
              <ion-note class="rejected" slot="end">
                <ion-skeleton-text animated></ion-skeleton-text>
              </ion-note>
            </ion-item>
          </div>
        </div>
      </ion-col>
      <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
        <div class="right-side">
          <div class="details">

            <ion-item class="video">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="resume">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="skills">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="experiences">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="area">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="nationality">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="gender">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="university">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="driving-license">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="mother-nationality">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="id-number">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="civil-front">
              <div tabindex="0"></div>

              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
                <span>
                  <ion-skeleton-text class="civil-id-image" animated></ion-skeleton-text>
                </span>
              </ion-label>
            </ion-item>

            <ion-item class="civil-back">
              <div tabindex="0"></div>

              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
                <span>
                  <ion-skeleton-text class="civil-id-image" animated></ion-skeleton-text>
                </span>
              </ion-label>
            </ion-item>

            <ion-item class="bank">
              <div tabindex="0"></div>
              <ion-label>
                <h5>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </h5>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>

            <ion-item class="created-at">
              <div tabindex="0"></div>
              <ion-label>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
                <p>
                  <ion-skeleton-text animated></ion-skeleton-text>
                </p>
              </ion-label>
            </ion-item>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<!--------------------------- fulltimer-view -------------------->

<div class="fulltimer-view" *ngIf="loading && type == 'fulltimer-view'">

  <h2 class="fulltimer-name">
    <ion-skeleton-text animated></ion-skeleton-text>
  </h2>

  <ion-skeleton-text class="tag" animated></ion-skeleton-text>

  <ion-item class="phone">
    <div tabindex="0"></div>
    <ion-label>
      <h5>Call</h5>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item class="whatsapp">
    <div tabindex="0"></div>
    <ion-icon slot="start" name="logo-whatsapp"></ion-icon>
    <ion-label>
      <h5>Whatsapp</h5>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item class="email" lines="none">
    <div tabindex="0"></div>
    <ion-label>
      <h5>Email</h5>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item class="nationality" lines="none">
    <div tabindex="0"></div>
    <ion-label>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item class="area" lines="none">
    <div tabindex="0"></div>
    <ion-label>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <ion-item class="created-at" lines="none">
    <div tabindex="0"></div>
    <ion-label>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-label>
  </ion-item>

  <div class="suggestion-wrapper">
    <h5 class="heading">Full-timer has been</h5>

    <ion-item>
      <div tabindex="0"></div>
      <ion-label>Suggested</ion-label>
      <ion-note slot="end">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-note>
    </ion-item>

    <ion-item>
      <div tabindex="0"></div>
      <ion-label>Accepted</ion-label>
      <ion-note class="accepted" slot="end">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-note>
    </ion-item>

    <ion-item>
      <div tabindex="0"></div>
      <ion-label>Rejected</ion-label>
      <ion-note class="rejected" slot="end">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-note>
    </ion-item>
  </div>

</div>

<!--------------------------- request-detail -------------------->

<div class="request-detail" *ngIf="loading && type == 'request-detail'">

  <div>
    <h4 class="company-name" tappable>
      <ion-skeleton-text animated class="title"></ion-skeleton-text>
    </h4>
    <p class="looking-for">
      <ion-skeleton-text animated class="title"></ion-skeleton-text>
    </p>
    <p class="requirement">
      <ion-skeleton-text animated class="title"></ion-skeleton-text>
    </p>
    <p class="txt-additional-info">
      <ion-skeleton-text animated class="title"></ion-skeleton-text>
    </p>
    <p class="requested-by">
      <ion-skeleton-text animated class="title"></ion-skeleton-text>
    </p>
    <p class="requested-by">
      <ion-skeleton-text animated class="title"></ion-skeleton-text>
    </p>
    <h4 class="txt-activities">Recent Activity</h4>
    <p class="activity-container">
      <ion-skeleton-text animated class="title"></ion-skeleton-text>
    </p>
  </div>
</div>

<!--------------------------- request-list -------------------->

<div class="request-list" *ngIf="loading && type == 'request-list'">

  <ion-card>
    <ion-card-content>
      <h1>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h1>
      <h3>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h3>

      <p class="txt-additional-info">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </p>
      <small class="time">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </small>
      <div class="content-footer">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <h1>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h1>

      <h3>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h3>

      <p class="txt-additional-info">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </p>
      <small class="time">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </small>
      <div class="content-footer">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <h1>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h1>
      <h3>
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </h3>

      <p class="txt-additional-info">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </p>
      <small class="time">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </small>
      <div class="content-footer">
        <ion-skeleton-text animated class="title"></ion-skeleton-text>
      </div>
    </ion-card-content>
  </ion-card>
</div>

<!--------------------------- note-list -------------------->

<div class="note-list" *ngIf="loading && type == 'note-list'">
  <ion-card>

    <ion-skeleton-text class="badge" animated></ion-skeleton-text>

    <p class="note">
      <ion-skeleton-text animated></ion-skeleton-text>

      <ion-skeleton-text animated></ion-skeleton-text>
    </p>

    <p class="txt-time">
      <ion-skeleton-text animated></ion-skeleton-text>
    </p>
  </ion-card>

  <ion-card>

    <ion-skeleton-text class="badge" animated></ion-skeleton-text>

    <p class="note">
      <ion-skeleton-text animated></ion-skeleton-text>

      <ion-skeleton-text animated></ion-skeleton-text>
    </p>

    <p class="txt-time">
      <ion-skeleton-text animated></ion-skeleton-text>
    </p>
  </ion-card>

  <ion-card>

    <ion-skeleton-text class="badge" animated></ion-skeleton-text>

    <p class="note">
      <ion-skeleton-text animated></ion-skeleton-text>

      <ion-skeleton-text animated></ion-skeleton-text>
    </p>

    <p class="txt-time">
      <ion-skeleton-text animated></ion-skeleton-text>
    </p>
  </ion-card>

</div>

<!--------------------------- company-view -------------------->

<div class="company-view" *ngIf="loading && type == 'company-view'">

  <ion-grid>
    <ion-row>
      <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
        <div class="company-view left-side">
          <h3 class="company-name">

            <ion-thumbnail>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-thumbnail>

            <label>
              <ion-skeleton-text animated></ion-skeleton-text>
            </label>
          </h3>

          <h4 class="company-name-english">
            <ion-skeleton-text animated></ion-skeleton-text>
          </h4>

          <h4 class="company-name-arabic arabic">
            <ion-skeleton-text animated></ion-skeleton-text>
          </h4>

          <div class="last-activity">
            <p class="followup">
              <ion-skeleton-text animated></ion-skeleton-text>
            </p>

            <p class="last-followup">
              <ion-skeleton-text animated></ion-skeleton-text>
            </p>
          </div>

          <p class="email">
            <ion-skeleton-text animated></ion-skeleton-text>
          </p>

          <p class="hourly-rate">
            <ion-skeleton-text animated></ion-skeleton-text>
          </p>

          <p class="description">
            <ion-skeleton-text animated></ion-skeleton-text>
          </p>

          <p class="description arabic">
            <ion-skeleton-text animated></ion-skeleton-text>
          </p>
          <h5>Last Activity</h5>
          <ion-card class="last-activity">
            <ion-card-content>
              <ion-badge>
                <ion-skeleton-text animated></ion-skeleton-text>
              </ion-badge>

              <h5 class="note">
                <ion-skeleton-text animated></ion-skeleton-text>
              </h5>

              <p class="txt-time">
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>

              <p class="txt-time">
                <ion-skeleton-text animated></ion-skeleton-text>
              </p>
            </ion-card-content>
          </ion-card>

          <ion-card>
            <ion-card-content>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-card-content>
          </ion-card>
        </div>
      </ion-col>

      <ion-col size-xs="12" size-sm="12" size-md="6" size-lg="6" size-xl="6">
        <div class="company-view right-side">
          <h5>Manage</h5>

          <ion-card>
            <ion-card-content>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-content>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-content>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-card-content>
          </ion-card>
          <ion-card>
            <ion-card-content>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-card-content>
          </ion-card>

          <ion-card>
            <ion-card-content>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-card-content>
          </ion-card>

          <ion-card>
            <ion-card-content>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-card-content>
          </ion-card>

          <ion-card>
            <ion-card-content>
              <ion-skeleton-text animated></ion-skeleton-text>
            </ion-card-content>
          </ion-card>

        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<!--------------------------- company-list -------------------->

<div class="company-list" *ngIf="loading && type == 'company-list'">
  <ion-item tappable lines="none">
    <div tabindex="0"></div>
    <ion-avatar slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-avatar>

    <ion-label class="ion-text-wrap">
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <div class="small-detail">
        <p>
          <ion-skeleton-text animated></ion-skeleton-text>
        </p>
        <ng-container>
          <p>
            <ion-skeleton-text animated></ion-skeleton-text>
          </p>
        </ng-container>
      </div>
      <ion-badge class="company_status_badge danger" color="light" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
      <ion-badge slot="start" color="light">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
      <ion-badge slot="start" color="light">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
      <ion-badge slot="start" color="light">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
    </ion-label>
  </ion-item>

  <ion-item tappable lines="none">
    <div tabindex="0"></div>
    <ion-avatar slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-avatar>

    <ion-label class="ion-text-wrap">
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <div class="small-detail">
        <p>
          <ion-skeleton-text animated></ion-skeleton-text>
        </p>
        <ng-container>
          <p>
            <ion-skeleton-text animated></ion-skeleton-text>
          </p>
        </ng-container>
      </div>
      <ion-badge class="company_status_badge danger" color="light" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
      <ion-badge slot="start" color="light">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
      <ion-badge slot="start" color="light">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
      <ion-badge slot="start" color="light">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
    </ion-label>
  </ion-item>

  <ion-item tappable lines="none">
    <div tabindex="0"></div>
    <ion-avatar slot="start">
      <ion-skeleton-text animated></ion-skeleton-text>
    </ion-avatar>

    <ion-label class="ion-text-wrap">
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <div class="small-detail">
        <p>
          <ion-skeleton-text animated></ion-skeleton-text>
        </p>
        <ng-container>
          <p>
            <ion-skeleton-text animated></ion-skeleton-text>
          </p>
        </ng-container>
      </div>
      <ion-badge class="company_status_badge danger" color="light" slot="start">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
      <ion-badge slot="start" color="light">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
      <ion-badge slot="start" color="light">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
      <ion-badge slot="start" color="light">
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-badge>
    </ion-label>
  </ion-item>
</div>

<!--------------------------- contact-list -------------------->

<div class="contact-list" *ngIf="loading && type == 'contact-list'">

  <ion-item>
    <div tabindex="0"></div>
    <ion-label>
      <h4>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h4>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <a href="#">
          <ion-icon name="mail-outline"></ion-icon>
          <ion-skeleton-text animated></ion-skeleton-text>
        </a>
      </p>
      <p>
        <a href="#">
          <ion-icon name="call-outline"></ion-icon>
          <ion-skeleton-text animated></ion-skeleton-text>
        </a>
      </p>
      <p>
        <a>
          <ion-icon name="time-outline"></ion-icon>
          <ion-skeleton-text animated></ion-skeleton-text>
        </a>
      </p>
      <div class="counts">
        <ion-badge>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-badge>
        <ion-badge>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-badge>
      </div>
    </ion-label>
  </ion-item>
  <ion-item>
    <div tabindex="0"></div>
    <ion-label>
      <h4>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h4>
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>
      <p>
        <a href="#">
          <ion-icon name="mail-outline"></ion-icon>
          <ion-skeleton-text animated></ion-skeleton-text>
        </a>
      </p>
      <p>
        <a href="#">
          <ion-icon name="call-outline"></ion-icon>
          <ion-skeleton-text animated></ion-skeleton-text>
        </a>
      </p>
      <p>
        <a>
          <ion-icon name="time-outline"></ion-icon>
          <ion-skeleton-text animated></ion-skeleton-text>
        </a>
      </p>
      <div class="counts">
        <ion-badge>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-badge>
        <ion-badge>
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-badge>
      </div>
    </ion-label>
  </ion-item>
</div>

<!--------------------------- brand-list -------------------->

<div class="brand-list" *ngIf="loading && type == 'brand-list'">
  <ion-card>
    <ion-item>
      <div tabindex="0"></div>
      <ion-thumbnail>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
      <h1>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h1>
    </ion-item>
  </ion-card>

  <ion-card>
    <ion-item>
      <div tabindex="0"></div>
      <ion-thumbnail>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
      <h1>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h1>
    </ion-item>
  </ion-card>

  <ion-card>
    <ion-item>
      <div tabindex="0"></div>
      <ion-thumbnail>
        <ion-skeleton-text animated></ion-skeleton-text>
      </ion-thumbnail>
      <h1>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h1>
    </ion-item>
  </ion-card>
</div>

<!--------------------------- mall-list -------------------->

<div class="brand-list mall-list" *ngIf="loading && type == 'mall-list'">
  <ion-card>
    <ion-item>
      <div tabindex="0"></div>
      <h1>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h1>
    </ion-item>
  </ion-card>
  <ion-card>
    <ion-item>
      <div tabindex="0"></div>
      <h1>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h1>
    </ion-item>
  </ion-card>
  <ion-card>
    <ion-item>
      <div tabindex="0"></div>
      <h1>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h1>
    </ion-item>
  </ion-card>
</div>

<!--------------------------- company-file-list -------------------->

<div class="company-file-list" *ngIf="loading && type == 'company-file-list'">
  <ion-card class="document">
    <ion-card-content>
      <img class="icon" src="assets/images/ic_save.svg" />
      <span class="meta-data">
        <ion-skeleton-text animated></ion-skeleton-text>
      </span>
      <span class="meta-data">
        <ion-skeleton-text animated></ion-skeleton-text>
      </span>
      <h5>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h5>
      <p class="document-detail">
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
      <p class="date">
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-card-content>
  </ion-card>
  <ion-card class="document">
    <ion-card-content>
      <img class="icon" src="assets/images/ic_save.svg" />
      <span class="meta-data">
        <ion-skeleton-text animated></ion-skeleton-text>
      </span>
      <span class="meta-data">
        <ion-skeleton-text animated></ion-skeleton-text>
      </span>
      <h5>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h5>
      <p class="document-detail">
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
      <p class="date">
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-card-content>
  </ion-card>
  <ion-card class="document">
    <ion-card-content>
      <img class="icon" src="assets/images/ic_save.svg" />
      <span class="meta-data">
        <ion-skeleton-text animated></ion-skeleton-text>
      </span>
      <span class="meta-data">
        <ion-skeleton-text animated></ion-skeleton-text>
      </span>
      <h5>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h5>
      <p class="document-detail">
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
      <p class="date">
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-card-content>
  </ion-card>
</div>

<!--------------------------- transfer-list -------------------->

<div class="transfer-list" *ngIf="loading && type=='transfer-list'">
  <ion-list>
    <ion-card>
      <ion-card-content>
        <h1>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h1>
        <h2>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h2>
        <h3>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h3>
        <h4>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h4>
      </ion-card-content>
    </ion-card>
    <ion-card>
      <ion-card-content>
        <h1>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h1>
        <h2>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h2>
        <h3>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h3>
        <h4>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h4>
      </ion-card-content>
    </ion-card>
    <ion-card>
      <ion-card-content>
        <h1>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h1>
        <h2>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h2>
        <h3>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h3>
        <h4>
          <ion-skeleton-text animated></ion-skeleton-text>
        </h4>
      </ion-card-content>
    </ion-card>
  </ion-list>
</div>

<!--------------------------- chart -------------------->

<div class="chart" *ngIf="loading && type=='chart'">
  <ion-card>
    <ion-card-content>
      <h1>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h1>
      
      <h2>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h2>

      <h3>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h3>

      <p>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>

      <small>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated></ion-skeleton-text>
        <ion-skeleton-text animated></ion-skeleton-text>
      </small>
    </ion-card-content>
  </ion-card>
</div>

<!--------------------------- store-list -------------------->

<div class="store-list" *ngIf="loading && type=='store-list'">
  <ion-card>
    <ion-card-content>
      <h5>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h5>

      <p class="icon-location">
        <ion-icon name="navigate-outline"></ion-icon>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>

      <p class="icon-location">
        <ion-icon name="pricetag-outline"></ion-icon>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
      
      <p>
        <ion-icon name="storefront-outline"></ion-icon>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-card-content>
  </ion-card>

  <ion-card>
    <ion-card-content>
      <h5>
        <ion-skeleton-text animated></ion-skeleton-text>
      </h5>

      <p class="icon-location">
        <ion-icon name="navigate-outline"></ion-icon>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
      <p class="icon-location">
        <ion-icon name="pricetag-outline"></ion-icon>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
      <p>
        <ion-icon name="storefront-outline"></ion-icon>
        <ion-skeleton-text animated></ion-skeleton-text>
      </p>
    </ion-card-content>
  </ion-card>
</div>

<!------------------ text-list -------------------->

<div class="store-card-list" *ngIf="loading && type == 'store-card-list'">
  <h3 class="title">
    <ion-skeleton-text></ion-skeleton-text>
  </h3>

  <h4 class="company-name">
    <ion-skeleton-text></ion-skeleton-text>
  </h4>
  <ion-card>
    <ion-card-content>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-card-content>
  </ion-card>
  <ion-card>
    <ion-card-content>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-card-content>
  </ion-card>
  <ion-card>
    <ion-card-content>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-card-content>
  </ion-card>
  <ion-card>
    <ion-card-content>
      <ion-skeleton-text></ion-skeleton-text>
    </ion-card-content>
  </ion-card>

</div>
