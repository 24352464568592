import { Store } from './store';
import { Company } from './company';
import { Bank } from './bank';
import { University } from './university';
import { Country } from './country';
import { Area } from './area';
import { CandidateIdCard } from './candidate-id-card';
import { Note } from "./note";
import { CandidateTag } from './candidate-tag';
import { CandidateEducation } from './candidate-education';
import { CandidateWorkHistory } from './candidate-work-history';
import { CandidateCertificate } from './certificate';
import { CandidateWorkLogFeedback } from './candidate-work-log-feedback';

export class Candidate {
    employee_id: number;
    candidate_id: number;
    candidate_uid: string;
    candidate_area_uuid: string;
    store_id: number;
    bank_id: number;
    university_id: number;
    country_id: number;
    bank_account_name: string;
    candidate_iban: string;
    candidate_name: string;
    candidate_name_ar: string;
    candidate_personal_photo: string;
    candidate_email: string;
    candidate_phone: string;
    candidate_address_line1;
    candidate_birth_date: string;
    candidate_civil_id: string;
    candidate_civil_expiry_date: string;
    candidate_civil_photo_front: string;
    candidate_civil_photo_back: string;
    candidate_hourly_rate: any;
    candidate_status: string;
    approved: number;
    isProfileCompleted: any;
    civilExpired: any;
    candidate_job_search_status: any;
    candidate_job_search_updated_at: string;
    candidate_committed: any;
    candidate_preferred_time: string;
    age: string;
    candidate_objective: string;
    candidate_gender: any;
    candidate_driving_license: any;
    candidate_email_verification: any;
    skill: string;
    experience: string;
    candidate_resume: string;
    candidate_video: string;
    candidate_video_processed: any;
    candidate_created_at: string;
    candidate_updated_at: string;
    candidate_mom_kuwaiti: any;
    workHistory: any;
    rejectionRatio: any;
    acceptanceRatio: any;
    candidate_latitude: any;
    candidate_longitude: any;
    suggested: number;
    invited: any;
    isAlreadyInvited: boolean;//is invitation sent for given story
    invitationAccepted: any;
    invitationRejected: any;
    suggestionAccepted: any;
    suggestionRejected: any;
    deleted: any;
    invitationStats: any;
    avgTimeToViewInvitations: number;
    store: Store;
    company: Company;
    university: University;
    country: Country;
    area: Area;
    nationality: Country;
    bank: Bank;
    tags: string;
    candidateExperiences: any;
    candidateSkills: any;
    candidateEducations: CandidateEducation[];
    currency_code: string;
    candidateTags: CandidateTag[];
    candidateIdCard: CandidateIdCard;
    notes: Note[];
    pendingField: any[];
    certificates: CandidateCertificate[];
    candidateStats: any;
    candidateLinks: CandidateLink[];
    currentWorkHistory: CandidateWorkHistory;
}

export class CandidateLink {
  cl_uuid: string;
  candidate_id: number;
  title: string;
  url: string;
}

export class CandidateWorkingHour {
  candidate_working_hour_uuid: string;
  candidate_id: number;
  store_id: number;
  date: string;
  start_time: string;
  end_time: string;
  total_time: number;
  start_location_lat: string;
  start_location_long: string;
  end_location_lat: string;
  end_location_long: string;
  created_at: string;
  updated_at: string;
  note: string;
  appeal_uuid: string;//hours created by appeal
  store:Store;
  company: Company;
  candidateWorkLogFeedback:  CandidateWorkLogFeedback;
  dateListByCandidate: any[];
}
