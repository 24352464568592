import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './providers/auth.service';
import { LoginGuard } from './providers/guards/login-guard.service';
import { SelectiveLoadingStrategy } from './util/SelectiveLoadingStrategy';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/logged-in/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/start-pages/login/login.module').then(m => m.LoginPageModule),
    canActivate: [LoginGuard],
    data: {
      name: 'LoginPage'
    }
  },
  {
    path: 'candidate-form',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-form/candidate-form.module').then(m => m.CandidateFormPageModule),
    canActivate: [AuthService],
    data: {
      navDisable: true,
      name: 'CandidateFormPage'
    }
  },
  {
    path: 'candidate-list',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-list/candidate-list.module').then(m => m.CandidateListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateListPage'
    }
  },
  {
    path: 'incomplete-candidate-list',
    loadChildren: () => import('./pages/logged-in/candidate/incomplete-candidate-list/incomplete-candidate-list.module').then(m => m.IncompleteCandidateListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'IncompleteCandidateListPage'
    }
  },
  {
    path: 'candidate-bank-info-list',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-bank-info-list/candidate-bank-info-list.module').then(m => m.CandidateBankInfoListModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateBankInfoList'
    }
  },
  {
    path: 'candidate-view',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-view/candidate-view.module').then(m => m.CandidateViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateViewPage',
      navDisable: true,
    }
  },
  {
    path: 'expired-id',
    loadChildren: () => import('./pages/logged-in/candidate/expired-id/expired-id.module').then(m => m.ExpiredIdPageModule),
    canActivate: [AuthService],
    data: {
      name: 'ExpiredIdPage'
    }
  },
  {
    path: 'generate-id',
    loadChildren: () => import('./pages/logged-in/candidate/generate-id/generate-id.module').then(m => m.GenerateIdPageModule),
    canActivate: [AuthService],
    data: {
      name: 'GenerateIdPage'
    }
  },
  {
    path: 'country-list',
    loadChildren: () => import('./pages/logged-in/country/country-list/country-list.module').then(m => m.CountryListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CountryListPage'
    }
  },
  {
    path: 'country-view',
    loadChildren: () => import('./pages/logged-in/country/country-view/country-view.module').then(m => m.CountryViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CountryViewPage',
      navDisable: true,
    }
  },
  {
    path: 'store-form',
    loadChildren: () => import('./pages/logged-in/store/store-form/store-form.module').then(m => m.StoreFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'StoreFormPage'
    }
  },
  {
    path: 'store-list',
    loadChildren: () => import('./pages/logged-in/store/store-list/store-list.module').then(m => m.StoreListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'StoreListPage'
    }
  },
  {
    path: 'store-view',
    loadChildren: () => import('./pages/logged-in/store/store-view/store-view.module').then(m => m.StoreViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'StoreViewPage',
      navDisable: true,
    }
  },
  {
    path: 'university-list',
    loadChildren: () => import('./pages/logged-in/university/university-list/university-list.module').then(m => m.UniversityListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'UniversityListPage'
    }
  },
  {
    path: 'university-view',
    loadChildren: () => import('./pages/logged-in/university/university-view/university-view.module').then(m => m.UniversityViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'UniversityViewPage'
    }
  },
  {
    path: 'no-internet',
    loadChildren: () => import('./pages/errors/no-internet/no-internet.module').then(m => m.NoInternetPageModule),
    data: {
      name: 'NoInternetPage',
    }
  },
  {
    path: 'server-error',
    loadChildren: () => import('./pages/errors/server-error/server-error.module').then(m => m.ServerErrorPageModule),
    data: {
      name: 'ServerErrorPage',
    }
  },
  {
    path: 'not-found',
    loadChildren: () => import('./pages/errors/not-found/not-found.module').then(m => m.NotFoundPageModule),
    data: {
      name: 'NotFoundPage',
    }
  },
  {
    path: 'company-view',
    loadChildren: () => import('./pages/logged-in/company/company-view/company-view.module').then(m => m.CompanyViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyViewPage',
      navDisable: true,
    }
  },
  {
    path: 'company-form',
    loadChildren: () => import('./pages/logged-in/company/company-form/company-form.module').then(m => m.CompanyFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyFormPage',
      navDisable: true,
    }
  },
  {
    path: 'candidate-review-list',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-review-list/candidate-review-list.module').then(m => m.CandidateReviewListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateReviewListPage',
    }
  },
  {
    path: 'company-followup-note',
    loadChildren: () => import('./pages/logged-in/company/company-followup-note/company-followup-note.module').then(m => m.CompanyFollowupNotePageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyFollowupNotePage',
    }
  },
  {
    path: 'company-request-form',
    loadChildren: () => import('./pages/logged-in/company/company-request-form/company-request-form.module').then(m => m.CompanyRequestFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyRequestFormPage',
    }
  },
  {
    path: 'brand-view',
    loadChildren: () => import('./pages/logged-in/company/brand-view/brand-view.module').then(m => m.BrandViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'BrandViewPage',
    }
  },
  {
    path: 'mall-form',
    loadChildren: () => import('./pages/logged-in/mall/mall-form/mall-form.module').then(m => m.MallFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'MallFormPage'
    }
  },
  {
    path: 'mall-list',
    loadChildren: () => import('./pages/logged-in/mall/mall-list/mall-list.module').then(m => m.MallListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'MallListPage'
    }
  },
  {
    path: 'mall-view',
    loadChildren: () => import('./pages/logged-in/mall/mall-view/mall-view.module').then(m => m.MallViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'MallViewPage'
    }
  },
  {
    path: 'store-manager-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/store/store-manager-form/store-manager-form.module').then( m => m.StoreManagerFormPageModule)
  },
  {
    path: 'company-request-list',
    loadChildren: () => import('./pages/logged-in/company/company-request-list/company-request-list.module').then(m => m.CompanyRequestListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyRequestListPage',
    }
  },
  {
    path: 'request-form',
    loadChildren: () => import('./pages/logged-in/company/company-request-list/request-form/request-form.module').then(m => m.RequestFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'RequestFormPage',
    }
  },
  {
    path: 'request-view',
    loadChildren: () => import('./pages/logged-in/company/company-request-view/company-request-view.module').then(m => m.CompanyRequestViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyRequestViewPage',
    }
  },
  {
    path: 'assigned-expired-civil',
    loadChildren: () => import('./pages/logged-in/candidate/assigned-expired-civil/assigned-expired-civil.module').then( m => m.AssignedExpiredCivilPageModule),
    canActivate: [AuthService],
    data: {
      name: 'AssignedExpiredCivilPage',
    }
  },
  {
    path: 'assigned-idle-candidates',
    loadChildren: () => import('./pages/logged-in/candidate/assigned-idle-candidates/assigned-idle-candidates.module').then( m => m.AssignedIdleCandidatesPageModule),
    canActivate: [AuthService],
    data: {
      name: 'AssignedIdleCandidatesPage',
    }
  },
  {
    path: 'candidate-committed-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/candidate-committed-form/candidate-committed-form.module').then( m => m.CandidateCommittedFormPageModule)
  },
  {
    path: 'team-list',
    loadChildren: () => import('./pages/logged-in/team/team-list/team-list.module').then( m => m.TeamListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'TeamListPage'
    }
  },
  {
    path: 'team-view',
    loadChildren: () => import('./pages/logged-in/team/team-view/team-view.module').then( m => m.TeamViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'TeamViewPage'
    }
  },
  {
    path: 'fulltimer',
    loadChildren: () => import('./pages/logged-in/fulltimer/fulltimer-view/fulltimer-view.module').then( m => m.FulltimerViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'FulltimerViewPage',
      navDisable: true
    }
  },
  {
    path: 'fulltimer-form',
    loadChildren: () => import('./pages/logged-in/fulltimer/fulltimer-form/fulltimer-form.module').then( m => m.FulltimerFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'FulltimerFormPage',
      navDisable: true
    }
  },

  {
    path: 'candidate-search',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-search/candidate-search.module').then(m => m.CandidateSearchPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateSearchPage',
      navDisable: true,
    }
  },

  {
    path: 'fulltimer-search',
    loadChildren: () => import('./pages/logged-in/fulltimer/fulltimer-search/fulltimer-search.module').then( m => m.FulltimerSearchPageModule),
    canActivate: [AuthService],
    data: {
      name: 'FulltimerSearchPage',
      navDisable: true
    }
  },

  {
    path: 'company-followup-list',
    loadChildren: () => import('./pages/logged-in/company/company-followup-list/company-followup-list.module').then(m => m.CompanyFollowupListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyFollowupListPage',
    }
  },

  {
    path: 'company-contacts',
    loadChildren: () => import('./pages/logged-in/company/company-contacts/company-contacts.module').then( m => m.CompanyContactsPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyContactsPage'
    }
  },

  {
    path: 'company-contact-view',
    loadChildren: () => import('./pages/logged-in/company/company-contact/company-contact-view/company-contact-view.module').then( m => m.CompanyContactViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyContactViewPage'
    }
  },

  {
    path: 'company-contact-form',
    loadChildren: () => import('./pages/logged-in/company/company-contact-form/company-contact-form.module').then( m => m.CompanyContactFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CompanyContactFormPage'
    }
  },
  {
    path: 'note-view',
    loadChildren: () => import('./pages/logged-in/note/note-view/note-view.module').then( m => m.NoteViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'NoteViewPage'
    }
  },
  {
    path: 'suggestion-view',
    loadChildren: () => import('./pages/logged-in/suggestion/suggestion-view/suggestion-view.module').then( m => m.SuggestionViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'SuggestionViewPage'
    }
  },
  {
    path: 'story-view',
    loadChildren: () => import('./pages/logged-in/story/story-view/story-view.module').then( m => m.StoryViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'StoryViewPage'
    }
  },
  {
    path: 'transfer-view',
    loadChildren: () => import('./pages/logged-in/transfer/transfer-view/transfer-view.module').then( m => m.TransferViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'TransferViewPage'
    }
  },
  {
    path: 'candidate-notes',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-notes/candidate-notes.module').then( m => m.CandidateNotesPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateNotesPage'
    }
  },
  {
    path: 'candidate-suggestions',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-suggestions/candidate-suggestions.module').then( m => m.CandidateSuggestionsPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateSuggestionsPage'
    }
  },
  {
    path: 'fulltimer-notes',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/fulltimer/fulltimer-notes/fulltimer-notes.module').then( m => m.FulltimerNotesPageModule)
  },
  {
    path: 'bank-list',
    loadChildren: () => import('./pages/logged-in/bank/bank-list/bank-list.module').then(m => m.BankListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'BankListPage'
    }
  },
  {
    path: 'candidate-invitations',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-invitations/candidate-invitations.module').then( m => m.CandidateInvitationsPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateInvitationsPage'
    }
  },
  {
    path: 'candidate-update-email',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-update-email/candidate-update-email.module').then(m => m.CandidateUpdateEmailPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateUpdateEmailPage'
    }
  },
  {
    path: 'staff',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/pickers/staff/staff.module').then( m => m.StaffPageModule),
    data: {
      name: 'StaffPage'
    }
  },
  {
    path: 'valocity',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/reports/valocity/valocity.module').then( m => m.ValocityPageModule),
    data: {
      name: 'ValocityPage'
    }
  },
  {
    path: 'voice-mail-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/yeaster/voice-mail/voice-mail-list/voice-mail-list.module').then( m => m.VoiceMailListPageModule)
  },
  {
    path: 'voice-mail-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/yeaster/voice-mail/voice-mail-view/voice-mail-view.module').then( m => m.VoiceMailViewPageModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./pages/logged-in/analytics/analytics.module').then( m => m.AnalyticsPageModule),
    canActivate: [AuthService],
    data: {
      name: 'AnalyticsPage'
    }
  },
  {
    path: 'client-feedback-backlog',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/client-feedback-backlog/client-feedback-backlog.module').then( m => m.ClientFeedbackBacklogPageModule),
    data: {
      name: 'ClientFeedbackBacklogPage'
    }
  },
  {
    path: 'invitation-list',
    loadChildren: () => import('./pages/logged-in/invitation-list/invitation-list.module').then( m => m.InvitationListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'invitationListPage'
    }
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/logged-in/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canActivate: [AuthService],
    data: {
      name: 'ChangePasswordPage'
    }
  },
  {
    path: 'candidate-salary-list',
    loadChildren: () => import('./pages/logged-in/candidate/candidate-salary-list/candidate-salary-list.module').then(m => m.CandidateSalaryListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'CandidateSalaryListPage'
    }
  },
  {
    path: 'store-option',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/store/store-option/store-option.module').then( m => m.StoreOptionPageModule)
  },
  {
    path: 'mall-option',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/mall/mall-option/mall-option.module').then( m => m.MallOptionPageModule)
  },
  {
    path: 'transfer-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/transfer/transfer-list-all/transfer-list-all.module').then( m => m.TransferListAllPageModule),
    data: {
      name: 'TransferListAllPage'
    }
  },
  {
    path: 'my-work',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/my-work/my-work.module').then( m => m.MyWorkPageModule),
    data: {
      name: 'MyWorkPage'
    }
  },
  {
    path: 'candidate-assigned-history',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/candidate-assigned-history/candidate-assigned-history.module').then( m => m.CandidateAssignedHistoryPageModule),
    data: {
      name: 'CandidateAssignedHistoryPage'
    }
  },
  {
    path: 'log-date-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/candidate-work-log/log-date-list/log-date-list.module').then( m => m.LogDateListPageModule),
    data: {
      name: 'LogDateListPage'
    }
  },
  {
    path: 'log-hour-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/candidate-work-log/log-hour-list/log-hour-list.module').then( m => m.LogHourListPageModule),
    data: {
      name: 'LogHourListPage'
    }
  },
  {
    path: 'app-error',
    loadChildren: () => import('./pages/errors/app-error/app-error.module').then( m => m.AppErrorPageModule)
  },
  {
    path: 'candidate-assign-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate-assign-form/candidate-assign-form.module').then( m => m.CandidateAssignFormPageModule)
  },
  {
    path: 'leave-request',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/leave-request/leave-request.module').then( m => m.LeaveRequestPageModule)
  },
  {
    path: 'fulltimer-suggestions',
    loadChildren: () => import('./pages/logged-in/fulltimer/fulltimer-suggestions/fulltimer-suggestions.module').then( m => m.FulltimerSuggestionsPageModule),
    canActivate: [AuthService],
    data: {
      name: 'FulltimerSuggestionsPage'
    }
  },
  {
    path: 'evaluation-report-list',
    loadChildren: () => import('./pages/logged-in/candidate/evaluation/evaluation-report-list/evaluation-report-list.module').then( m => m.EvaluationReportListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'EvaluationReportListPage'
    }
  },
  {
    path: 'evaluation-report-form',
    loadChildren: () => import('./pages/logged-in/candidate/evaluation/evaluation-report-form/evaluation-report-form.module').then( m => m.EvaluationReportFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'EvaluationReportFormPage'
    }
  },
  {
    path: 'evaluation-report-view',
    loadChildren: () => import('./pages/logged-in/candidate/evaluation/evaluation-report-view/evaluation-report-view.module').then( m => m.EvaluationReportViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'EvaluationReportViewPage'
    }
  },
  {
    path: 'my-expenses',
    loadChildren: () => import('./pages/logged-in/expense/expense-list/expense-list.module').then( m => m.ExpenseListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'ExpenseListPage'
    }
  },
  {
    path: 'expenses-form',
    loadChildren: () => import('./pages/logged-in/expense/expense-form/expense-form.module').then( m => m.ExpenseFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'ExpenseFormPage'
    }
  },
  {
    path: 'expenses-view',
    loadChildren: () => import('./pages/logged-in/expense/expense-view/expense-view.module').then( m => m.ExpenseViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'ExpenseViewPage'
    }
  },
  {
    path: 'leave-request-form',
    loadChildren: () => import('./pages/logged-in/leave/leave-request-form/leave-request-form.module').then( m => m.LeaveRequestFormPageModule),
    canActivate: [AuthService],
    data: {
      name: 'LeaveRequestFormPage'
    }
  },
  {
    path: 'leave-request-list',
    loadChildren: () => import('./pages/logged-in/leave/leave-request-list/leave-request-list.module').then( m => m.LeaveRequestListPageModule),
    canActivate: [AuthService],
    data: {
      name: 'LeaveRequestListPage'
    }
  },
  {
    path: 'leave-request-view',
    loadChildren: () => import('./pages/logged-in/leave/leave-request-view/leave-request-view.module').then( m => m.LeaveRequestViewPageModule),
    canActivate: [AuthService],
    data: {
      name: 'LeaveRequestViewPage'
    }
  },
  {
    path: 'update-password',
    loadChildren: () => import('./pages/start-pages/update-password/update-password.module').then(m => m.UpdatePasswordPageModule),
    data: {
      name: 'UpdatePasswordPage'
    }
  },
  {
    path: 'candidate-tags',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/candidate-tags/candidate-tags.module').then( m => m.CandidateTagsPageModule)
  },
  {
    path: 'candidate-warning-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/candidate-warning-list/candidate-warning-list.module').then( m => m.CandidateWarningListPageModule)
  },
  {
    path: 'candidate-warning-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/candidate-warning-form/candidate-warning-form.module').then( m => m.CandidateWarningFormPageModule)
  },

  {
    path: 'transfer-candidate-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/transfer/transfer-candidate-list/transfer-candidate-list.module').then( m => m.TransferCandidateListPageModule)
  },
   
  {
    path: 'company-candidates',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/company/company-candidates/company-candidates.module').then( m => m.CompanyCandidatesPageModule)
  },
   
  {
    path: 'company-registration-request-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/company/company-registration-request-list/company-registration-request-list.module').then( m => m.CompanyRegistrationRequestListPageModule)
  },
  {
    path: 'company-registration-request-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/company/company-registration-request-view/company-registration-request-view.module').then( m => m.CompanyRegistrationRequestViewPageModule)
  },

  {
    path: 'email-campaign-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/email-campaign/email-campaign-list/email-campaign-list.module').then( m => m.EmailCampaignListPageModule)
  },
  {
    path: 'email-campaign-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/email-campaign/email-campaign-view/email-campaign-view.module').then( m => m.EmailCampaignViewPageModule)
  },
  {
    path: 'email-campaign-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/email-campaign/email-campaign-form/email-campaign-form.module').then( m => m.EmailCampaignFormPageModule)
  },
  {
    path: 'update-account',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/update-account/update-account.module').then( m => m.UpdateAccountPageModule)
  },
  {
    path: 'store-assignment-request-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/store-assignment-request/store-assignment-request-list/store-assignment-request-list.module').then( m => m.StoreAssignmentRequestListPageModule)
  },
  {
    path: 'store-assignment-request-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/store-assignment-request/store-assignment-request-view/store-assignment-request-view.module').then( m => m.StoreAssignmentRequestViewPageModule)
  },
  {
    path: 'request-interview-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/company/request-interview-list/request-interview-list.module').then( m => m.RequestInterviewListPageModule)
  },
  {
    path: 'support',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/support/ticket-list/ticket-list.module').then( m => m.TicketListPageModule)
  },
  {
    path: 'ticket-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/support/ticket-view/ticket-view.module').then( m => m.TicketViewPageModule)
  },
  {
    path: 'ticket-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/support/ticket-form/ticket-form.module').then( m => m.TicketFormPageModule)
  },
  {
    path: 'firing-hitmap',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/company/firing-hitmap/firing-hitmap.module').then( m => m.FiringHitmapPageModule)
  },
  {
    path: 'interview-evaluation-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/interview-evaluation/interview-evaluation-form/interview-evaluation-form.module').then( m => m.InterviewEvaluationFormPageModule)
  },
  {
    path: 'interview-evaluation-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/interview-evaluation/interview-evaluation-view/interview-evaluation-view.module').then( m => m.InterviewEvaluationViewPageModule)
  },
  {
    path: 'interview-evaluation-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/candidate/interview-evaluation/interview-evaluation-list/interview-evaluation-list.module').then( m => m.InterviewEvaluationListPageModule)
  },

  {
    path: 'company-contract-form',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/company/company-contract/company-contract-form/company-contract-form.module').then( m => m.CompanyContractFormPageModule)
  },
  {
    path: 'company-contract-view',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/company/company-contract/company-contract-view/company-contract-view.module').then( m => m.CompanyContractViewPageModule)
  },
  {
    path: 'company-contract-list',
    canActivate: [AuthService],
    loadChildren: () => import('./pages/logged-in/company/company-contract/company-contract-list/company-contract-list.module').then( m => m.CompanyContractListPageModule)
  },

  {
    path: '**',
    redirectTo: 'not-found'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { enableTracing: false, preloadingStrategy: SelectiveLoadingStrategy })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { } 